<template>
    <div>
        <div class="px-4 py-4 fullComponent" v-if="itemsToDisplay" :style="`background-color: ${colorOfBg}`">
            <div class="white">
                <div class="align-center pb-4 titleAndRedirection" :style="`background-color: ${colorOfBg}`">
                    <div style="display:flex; align-items: center;">
                        <img class="mr-2" v-if="data.icon" style="width: 32px; height: 32px;" :src="data.icon" alt="Featured Icon">
                        <p class="ma-0 body-heading font-weight-semi-bold">{{ data.title }}</p>
                    </div>
                    
                    <div class="responsiveButton" v-if="isDesktopMode && data.displayJumpLink">
                        <v-btn
                            class="redirectButton"
                            v-if="data.displayJumpLink"
                            elevation="0"
                            @click="redirect()"
                            depressed
                            outlined
                        >
                            <span class="white-color-text body-text font-weight-semi-bold">{{ redirectToLabel }}</span>
                            <img :src=redirectIconPath style="margin-left: 10px;">
                        </v-btn>
                    </div>  
                </div>

                <div class="py-2" v-if="data.items.length > 1 && displayTabsCount > 1" :style="`background-color: ${colorOfBg}`">
                    <v-slide-group multiple active-class="success">
                        <v-slide-item v-for="item in data.items" class="tab" :key="item.tab_name">
                            <div>
                                <v-btn
                                    class="mr-4 tab-btn"
                                    :style="{
                                        color: [item.tab_name===activeTab ? 'white' : 'black'], 
                                        fontFamily: 'Inter', 'text-transform': 'none',
                                        backgroundColor: [item.tab_name===activeTab ? '#48ACEF' : 'white']
                                        }"
                                    depressed outlined
                                    @click="updateActiveTab(item.tab_name, item)"
                                    v-if="item.items.length > 0"
                                >
                                <span class=" body-text font-weight-semi-bold">{{ item.tab_name }}</span>
                                </v-btn>
                            </div>
                        </v-slide-item>
                    </v-slide-group>
                </div>
                <div v-if="data.item_type == 'PRODUCTS' || data.item_type == 'COLLECTIONS'" class="pt-2 pt-sm-4 product-slider" :style="`background-color: ${colorOfBg}`">
                    <v-slide-group class="listBox">
                        <template v-slot:prev>
                            <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_prepend_icon.svg" alt="prev-icon">
                        </template>

                        <template v-slot:next>
                            <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_append_icon.svg" alt="prev-icon">
                        </template>

                        <v-slide-item class="pr-4 item" v-for="item, index in activeItemGroup.items" :key="index">
                            <div>
                                <epharmacyProductComponent :product_data="item" ></epharmacyProductComponent>
                            </div>
                        </v-slide-item>
                    </v-slide-group>
                </div>
            </div>

            <div v-if="data.item_type==='DOCTORS'" :style="`background-color: ${colorOfBg}`" class="">
                <v-slide-group class="listBox" v-if="listings_type == 'LISTINGS-ONE-ROW'">
                    <template v-slot:prev>
                        <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_prepend_icon.svg" alt="prev-icon">
                    </template>

                    <template v-slot:next>
                        <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_append_icon.svg" alt="next-icon">
                    </template>

                    <v-slide-item class="pr-4 item" v-for="index in activeItemGroup.items.length" :key="index">
                        <div>
                            <epharmacy-doctor-component-1
                                :doctor_data="activeItemGroup.items[index - 1]" 
                            ></epharmacy-doctor-component-1>
                        </div>
                    </v-slide-item>
                </v-slide-group>

                <v-slide-group class="listBox" v-if="listings_type == 'LISTINGS-TWO-ROWS'">
                    <template v-slot:prev>
                        <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_prepend_icon.svg" alt="prev-icon">
                    </template>

                    <template v-slot:next>
                        <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_append_icon.svg" alt="prev-icon">
                    </template>

                    <v-slide-item class="" v-for="item, index in activeItemGroup.items" :key="index">
                        <div class="d-flex flex-column">
                            <epharmacy-doctor-component-2
                                v-if="index % 2 == 0"
                                :doctor_data="activeItemGroup.items.slice(index, index+2)"
                            ></epharmacy-doctor-component-2>
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </div>

            <div class="pt-2 pt-sm-4 product-slider" v-if="data.type == 'ARTICLES'" :style="`background-color: ${colorOfBg}`">
                <v-slide-group class="listBox">
                    <template v-slot:prev>
                        <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_prepend_icon.svg" alt="prev-icon">
                    </template>

                    <template v-slot:next>
                        <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/v_slider_group_append_icon.svg" alt="next-icon">
                    </template>

                    <v-slide-item class="pr-4 item" v-for="article, index in data.items" :key="index">
                        <div class="clickable-pointer" @click="redirectToArticlePage(article.link)">
                            <div style="width: 100%;">
                                <img width="356" height="200" class="" :src="article.img_url" alt="health-articles-image">
                            </div>

                            <div>
                                <p class="ma-0 body-heading font-weight-bold" align="left">{{ article.title }}</p>
                            </div>
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </div>

            <div class="responsiveButtonForMobile" v-if="!isDesktopMode && data.displayJumpLink" :style="`background-color: ${colorOfBg}`">
                <v-btn
                    class="redirectButtonForMobile"
                    v-if="data.displayJumpLink"
                    elevation="0"
                    @click="redirect()"
                    depressed
                    outlined
                >
                    <span class="white-color-text">{{ redirectToLabel }}</span>
                    <img :src=redirectIconPath style="margin-left: 10px;">
                </v-btn> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MultipleStyleListingsComponent",
    components: {
        'epharmacyProductComponent': () => import('../ePharmacyComponents/productHomeComponent.vue'),
        'epharmacy-doctor-component-1': () => import('../ePharmacyComponents/doctorHomeComponent1.vue'),
        'epharmacy-doctor-component-2': () => import('../ePharmacyComponents/doctorHomeComponent2.vue'),
    },
    data() {
        return {
            isDesktopMode: false,
            colorOfBg: '',
            activeTab: '',
            activeItemGroup: null,
            list: {},
            redirectToLabel: '',
            itemsToDisplay: false,
            displayTabsCount: 0,
            redirectIconPath: "https://s3iconimages.mymedicine.com.mm/redirectionWhiteArrow.svg"
        }
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        listings_type: {
            type: String,
            required: false
        }
    },
    // props: [
    //     'title', 'icon', 'Items', 'redirection', 'redirectLabel', 'redirectLabelMM', 
    //     'item_type', 'bgColor', 'buttonText'
    // ],
    mounted() {
        this.checkScreenMode();
        window.addEventListener("resize",  this.checkScreenMode);

        if (this.data.background === 'blue') {
            this.colorOfBg = '#F4FAFF';
        } else if (this.data.background === 'grey') {
            this.colorOfBg = '#F8F8F8';
        } else {
            this.colorOfBg = 'white';
        }

        for (let item of this.data.items) {
            if (item.items && item.items.length > 0) {
                this.activeTab = item.tab_name;
                this.activeItemGroup = item;
                break;
            }
        }

        if(this.$i18n.locale=='en')
            this.redirectToLabel = this.data.redirection_label;
        else
            this.redirectToLabel = this.data.redirection_label_burmese;

        for (let item of this.data.items) {
            if (item.items && item.items.length > 0) {
                this.itemsToDisplay = true;
                break;
            }
        }

        for (let item of this.data.items) {
            if (item.items && item.items.length > 0) {
                this.displayTabsCount++;
            }
        }

        if (this.data.type == 'ARTICLES') {
            this.itemsToDisplay = true;
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenMode);
    },
    methods: { 
        checkScreenMode() {
            this.isDesktopMode = window.matchMedia("(min-width: 500px)").matches;
        },      
        updateActiveTab(tabName, itemGrp) {
            this.activeTab = tabName;
            this.activeItemGroup = itemGrp;
            
        },
        redirectToArticlePage(redirectLink) {
            window.open(redirectLink);
        },
        redirect() {
            if (!(this.data.item_type === 'PRODUCTS' || this.data.item_type === 'DOCTORS' || this.data.item_type === 'COLLECTIONS')) {
                if(this.data.redirection === 'CatalogPage' && this.data.custom_url_flag) {
                    console.log(this.data.catalogPageType)
                    this.$router.push({
                        name: this.data.redirection,
                        params: {
                            catalog_name: (this.data.catalog_page_type).toLowerCase(),
                            category_id: this.data.custom_url.cat_id
                        }
                    });
                } else if (this.data.redirection === 'SpecializationsPage' && this.data.custom_url_flag) {
                    this.$router.push({
                        path: "/customer/viewDoctors?type=Category&type1=" + this.data.custom_url._id + "&type2="+ this.data.custom_url.name
                    });
                } else {
                    this.$router.push({
                        name: this.data.redirection,
                    });
                }
            } else {
                if (this.data.item_type === 'COLLECTIONS') {
                    this.$router.push({
                        name: 'CatalogPage',
                        params: {
                            catalog_name: 'collections',
                            category_id: this.activeItemGroup.tab_link
                        }
                    });
                } else if (this.data.item_type === 'PRODUCTS') {
                    this.$router.push({
                        name: 'CatalogPage',
                        params: {
                            catalog_name: 'categories',
                            category_id: this.activeItemGroup.tab_link
                        }
                    });
                } else if (this.data.item_type === 'DOCTORS') {
                    this.$router.push({
                        path: "/customer/viewDoctors?type=Category&type1=" + this.activeItemGroup.tab_link + "&type2="+ this.activeItemGroup.tab_name
                    });
                } else {
                    this.$router.push({
                        name: this.data.redirection
                    });
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';

.titleAndRedirection {
    display: flex;
    justify-content: space-between;
}
.responsiveButtonForMobile {
    padding-top: 20px;
}
.fullComponent {
    font-family: 'Inter';

    @media (max-width: 600px) {
        
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        border-radius: 8px;
    }

    @media (min-width: 1265px) {
        border-radius: 8px;
    }
}
.tab-btn.v-btn--outlined {
    border: thin solid #E0E0E0;
}

.tab {
    font-family: 'Inter';
}
.redirectButton.v-btn--outlined {
    border: thin solid #48ACEF;
    color: white;
    background-color: #48ACEF;
    text-transform: none;
    display: flex;
    justify-content:space-between;
}
.redirectButtonForMobile {
    border: thin solid #48ACEF;
    color: white;
    background-color: #48ACEF;
    text-transform: none;
    width: 100%;
    display: flex;
    justify-content:space-between;
    text-align: left !important;
}

</style>

<style lang="scss">
.listBox .v-slide-group__content {
    white-space: unset;
}
.product-slider .v-slide-group__next, .v-slide-group__prev {
    margin-top: auto;
    margin-bottom: auto;
    min-width: unset !important;
    flex: unset;
    min-height: 24px;
}
</style>